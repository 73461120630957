import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "社内勉強会 TechLunch で\"JavaScript AST ことはじめ\"という発表をしました",
  "date": "2018-06-22T04:00:00.000Z",
  "slug": "entry/2018/06/22/130000",
  "tags": ["medley"],
  "hero": "./2018_06_22.png",
  "heroAlt": "JavaScript ast"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`みなさん、こんにちは。開発本部エンジニアの平木です。こちらのブログの投稿自体はほぼ 1 年ぶりになりそうな勢いですが、みなさまお元気でしょうか?`}</p>
    <p>{`弊社で定期的に開催してる社内勉強会 TechLunch で自分の順番が回ってきたため、どうしようか迷った末に`}<strong parentName="p">{`JavaScript AST ことはじめ`}</strong>{`という発表をしたので、そのことについて書いていきます。`}</p>
    <h1>{`なぜ JavaScript AST について話そうと思ったのか`}</h1>
    <p>{`現在、弊社のエンジニアメンバーのバックグラウンドで一番多数派なのは「元サーバサイドエンジニア」です。もちろん、業務ではサーバサイド・フロントエンド・ネイティブアプリとバックグラウンドに関わらず、必要に応じて分け隔てなく開発しています。`}</p>
    <p>{`とはいえ、ちゃんとサービス開発自体はできるとしても、やはり得意な分野以外で基本原理など含めて把握して開発できるかというと、ちょっと難しいところもあります。しかし、そういった基本原理なんかを知っていると、その言語やツールなどの理解が捗るのは確かですよね。`}</p>
    <p>{`そんな中、弊社で開発している人間がほぼ全て恩恵を受けているはずなのに、具体的にどんな風に動いているのかが一番分かりにくいであろう`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Babel`}</code>{`ひいては JavaScript AST の話をしたら、まあ興味持って話を聞いてくれるかなーということででこのテーマを選んだ次第です。`}</p>
    <h1>{`どのように伝えるか`}</h1>
    <p>{`自分は JavaScript AST についてとても詳しいわけではないのですが、以前仕事で`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`acorn`}</code>{`を使ってコンバータみたいなのを作ったりしていたので、それなりに興味は持っているという人間です。`}</p>
    <p>{`ですので、どうやって紹介をしようかと悩んだ結果、ほぼ全面的に`}<a parentName="p" {...{
        "href": "https://astexplorer.net/"
      }}>{`AST Explore`}</a>{`に頼っていくというスタイルにしました。AST Explore は本当に最高ですね。前述の仕事をしていたときはこんな便利なツールはなかったんで、ひたすら AST に変換するコード書いては出来た AST を見て、それをトランスフォームさせて結果と睨めっこして試行錯誤するという毎日でした。`}</p>
    <p>{`ということで、当日のスライドはこちらになります。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/45c318b24d7741728e858deb887957c0" title="JavaScript AST ことはじめ　/JavaScript AST" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <p>{`スライドで紹介したデモはそれぞれこちらになります。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://astexplorer.net/#/gist/82742676286b2dced595ce36cdeb8aae/latest"
        }}>{`https://astexplorer.net/#/gist/82742676286b2dced595ce36cdeb8aae/latest`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://astexplorer.net/#/gist/52d871c2f3a8d9cefc68d17badf4f165/latest"
        }}>{`https://astexplorer.net/#/gist/52d871c2f3a8d9cefc68d17badf4f165/latest`}</a></li>
    </ul>
    <h1>{`今回伝えたかったこと`}</h1>
    <p>{`まず、AST が JavaScript の発展にとても寄与しているものだということを知ってもらいたかったため、JavaScript AST の今までの簡単な流れや、現在どのような形で使われているのかの説明をしました。(個人的に Node.js の誕生と JavaScript AST の存在が現在のフロントエンドの発展にとても重要だと思っているので)`}</p>
    <p>{`最初のうちは聞いてる人も「何の話なんだろ…」感がありましたが、やはり実際に自分が使っているツールなどに使われているという説明をしたあとだと、聞いているメンバーも俄然興味が出てきたという雰囲気になった気がします(当社比)。`}</p>
    <p>{`AST の文法などは自分が説明するよりは、ちゃんと資料が揃っているので必要な部分以外簡略化しました。逆にちょっと端折りすぎたきらいもありますが、興味を持ったときに何となくでも調べる道標くらいにはなるかなと考えています。`}</p>
    <p>{`次に知ってもらいたかったのは、やろうと思えば Babel のプラグインなんかも AST で作れちゃいますよということでした。仮にいきなり「Babel プラグイン作りましょう」となったとしても正直あまりピンと来ないと思いますが、どういう原理でプロダクトが動いているのか?が分かると、`}<a parentName="p" {...{
        "href": "https://github.com/jamiebuilds/babel-handbook"
      }}>{`babel-handbook`}</a>{`などを読んでも理解が進むのではないかと思います。`}</p>
    <h1>{`AST Explore のこと`}</h1>
    <p>{`このように今回の発表で全面的に活躍した AST Explore ですが、TechLunch 中でも軽い説明だけで使ってしまったので、使いかたなど簡単にご紹介していきます。`}</p>
    <h2>{`AST Explore とは`}</h2>
    <p><a parentName="p" {...{
        "href": "https://astexplorer.net/"
      }}>{`AST Explore`}</a>{`は`}<a parentName="p" {...{
        "href": "https://github.com/fkling"
      }}>{`Felix Kling`}</a>{`さんが、2014 年頃から開発しているプロダクトです。`}</p>
    <p>{`余談ですが、Felix さんは現在 Facebook で働いていらっしゃるようで、`}<a parentName="p" {...{
        "href": "https://github.com/facebook/jscodeshift"
      }}>{`facebook/jscodeshift`}</a>{`や`}<a parentName="p" {...{
        "href": "https://github.com/reactjs/react-docgen"
      }}>{`reactjs/react-docgen`}</a>{`なんかの開発にも携わっていらっしゃる模様。(react-docgen は`}<a parentName="p" {...{
        "href": "https://github.com/babel/babylon"
      }}>{`babylon`}</a>{`を使っているようですが)`}</p>
    <p>{`ここまで書いてきた通りに、このツールは色々な言語をコピペするだけで AST をツリー形式で分かりやすく表示したり、トランスフォームさせることができたりするという AST を触るには大変便利なツールです。去年の v2.0 のアップデートにより、セーブすると gist を匿名で作ってくれてリンクが生成されるなどの便利機能が付きました。`}</p>
    <p>{`プロジェクトの README に書いていますが、パーサだけであれば、かなりパースできるものが多く、また JavaScript / CSS / 正規表現 / Handlebars に関してはトランスフォームまでできるようになっています。`}</p>
    <p>{`README から抜粋すると以下のような感じです。`}</p>
    <h2>{`AST Explore でパースできるもの`}</h2>
    <ul>
      <li parentName="ul">{`CSS:`}
        <ul parentName="li">
          <li parentName="ul">{`cssom`}</li>
          <li parentName="ul">{`csstree`}</li>
          <li parentName="ul">{`postcss + postcss-safe-parser & postcss-scss`}</li>
          <li parentName="ul">{`rework`}</li>
        </ul>
      </li>
      <li parentName="ul">{`GraphQL`}</li>
      <li parentName="ul">{`Graphviz:`}
        <ul parentName="li">
          <li parentName="ul">{`redot`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Handlebars:`}
        <ul parentName="li">
          <li parentName="ul">{`glimmer`}</li>
          <li parentName="ul">{`handlebars`}</li>
        </ul>
      </li>
      <li parentName="ul">{`HTML:`}
        <ul parentName="li">
          <li parentName="ul">{`htmlparser2`}</li>
          <li parentName="ul">{`parse5`}</li>
        </ul>
      </li>
      <li parentName="ul">{`ICU`}</li>
      <li parentName="ul">{`JavaScript:`}
        <ul parentName="li">
          <li parentName="ul">{`acorn + acorn-jsx`}</li>
          <li parentName="ul">{`babel-eslint`}</li>
          <li parentName="ul">{`babylon`}</li>
          <li parentName="ul">{`espree`}</li>
          <li parentName="ul">{`esformatter`}</li>
          <li parentName="ul">{`esprima`}</li>
          <li parentName="ul">{`flow-parser`}</li>
          <li parentName="ul">{`recast`}</li>
          <li parentName="ul">{`shift`}</li>
          <li parentName="ul">{`traceur`}</li>
          <li parentName="ul">{`typescript`}</li>
          <li parentName="ul">{`typescript-eslint-parser`}</li>
          <li parentName="ul">{`uglify-js`}</li>
        </ul>
      </li>
      <li parentName="ul">{`JSON`}</li>
      <li parentName="ul">{`Lua:`}
        <ul parentName="li">
          <li parentName="ul">{`luaparse`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Markdown:`}
        <ul parentName="li">
          <li parentName="ul">{`remark`}</li>
        </ul>
      </li>
      <li parentName="ul">{`PHP`}
        <ul parentName="li">
          <li parentName="ul">{`php-parser`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Regular Expressions:`}
        <ul parentName="li">
          <li parentName="ul">{`regexp-tree`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Scala`}
        <ul parentName="li">
          <li parentName="ul">{`Scalameta`}</li>
        </ul>
      </li>
      <li parentName="ul">{`SQL:`}
        <ul parentName="li">
          <li parentName="ul">{`sqlite-parser`}</li>
        </ul>
      </li>
      <li parentName="ul">{`WebIDL`}</li>
      <li parentName="ul">{`YAML`}</li>
    </ul>
    <h2>{`実験的だったりするけどパースできるもの`}</h2>
    <ul>
      <li parentName="ul">{`ES6: arrow functions, destructuring, classes, …`}</li>
      <li parentName="ul">{`ES7 proposals: async/await, object rest / spread, …`}</li>
      <li parentName="ul">{`JSX`}</li>
      <li parentName="ul">{`Typed JavaScript Flow and TypeScript`}</li>
      <li parentName="ul">{`SASS`}</li>
    </ul>
    <h2>{`パースしたものをトランスフォームできるもの`}</h2>
    <ul>
      <li parentName="ul">{`JavaScript`}
        <ul parentName="li">
          <li parentName="ul">{`babel (v5, v6)`}</li>
          <li parentName="ul">{`ESLint (v1, v2, v3)`}</li>
          <li parentName="ul">{`jscodeshift`}</li>
          <li parentName="ul">{`tslint`}</li>
        </ul>
      </li>
      <li parentName="ul">{`CSS`}
        <ul parentName="li">
          <li parentName="ul">{`postcss`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Regular Expressions`}
        <ul parentName="li">
          <li parentName="ul">{`regexp-tree`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Handlebars`}
        <ul parentName="li">
          <li parentName="ul">{`glimmer`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`AST Explore の使い方の簡単な解説`}</h2>
    <p>{`サイトにアクセスするとこのような画面になっているはずです。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180622/20180622111218.png",
      "alt": "20180622111218.png"
    }}></img>
    <h2>{`メイン画面`}</h2>
    <p>{`JavaScript にフォーカスして解説していきますと、左ペインが AST に変換したいソースコード、右ペインが変換後の AST をツリー構造で見せています。`}</p>
    <p>{`初期表示時に、左ペインのソースコードをクリックすると該当箇所の AST ツリーが展開してハイライトします。また右ペインをポイントするとソースコードの該当箇所がハイライトします。お互いの関係が分かりやすい仕様になっています。`}</p>
    <img {...{
      "src": "https://layzie.d.pr/kfoFZu+"
    }}></img>
    <p>{`本来 JavaScript AST で生成されるものは JSON オブジェクトになりますが、右ペインの上の`}<strong parentName="p">{`Tree`}</strong>{`と`}<strong parentName="p">{`JSON`}</strong>{`のタブを切りかえることによって AST の表示を変更することができます。`}</p>
    <h2>{`ヘッダー部分`}</h2>
    <p>{`ヘッダーに色々な機能がまとまっています。`}</p>
    <p>{`初期表示では以下のようになっているはずです。`}</p>
    <ul>
      <li parentName="ul">{`Snippet`}
        <ul parentName="li">
          <li parentName="ul">{`俗にいうファイルメニュー。`}
            <ul parentName="li">
              <li parentName="ul">{`新規作成・(gist への)セーブ・(gist の)フォーク・シェアがある`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`JavaScript`}
        <ul parentName="li">
          <li parentName="ul">{`パースする言語選択`}
            <ul parentName="li">
              <li parentName="ul">{`ここで AST にしたい言語を切り替える`}</li>
              <li parentName="ul">{`選んだ言語によって`}<em parentName="li">{`Transform`}</em>{`が使えなくなる`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`acorn`}
        <ul parentName="li">
          <li parentName="ul">{`パーサ選択`}
            <ul parentName="li">
              <li parentName="ul">{`各言語のパーサを切り替える`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Transform`}
        <ul parentName="li">
          <li parentName="ul">{`トランスフォーマ選択`}
            <ul parentName="li">
              <li parentName="ul">{`選択した言語にトランスフォーマがあれば選択できるようになる`}</li>
              <li parentName="ul">{`こちらを選択すると 2 ペインだったのが 4 ペインになる(後述)`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`default`}
        <ul parentName="li">
          <li parentName="ul">{`ソースコードなどを書くときのキーバインド選択`}
            <ul parentName="li">
              <li parentName="ul">{`default / Vim / Emacs / Sublime の 4 種類がある`}</li>
              <li parentName="ul">{`うれしみがあります`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`?`}
        <ul parentName="li">
          <li parentName="ul">{`ヘルプ`}
            <ul parentName="li">
              <li parentName="ul">{`GitHub の README に飛ばされるだけです…`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2>{`JavaScript のトランスフォーム`}</h2>
    <p>{`先程説明したトランスフォームを選ぶと、メインの画面が 4 画面になります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180622/20180622111427.png",
      "alt": "20180622111427.png"
    }}></img>
    <p>{`今までの`}<em parentName="p">{`ソースコード`}</em>{`と`}<em parentName="p">{`AST ツリー`}</em>{`は変わりませんが、下に 2 つペインが追加されます。
左下がトランスフォーマコード、右下がトランスフォームした後のソースコードとなっています。`}</p>
    <p>{`左下のトランスフォーマを色々触っていくと左上のソースコードが変換されて、右下に表示されるという流れですね。`}</p>
    <p>{`以下 JavaScript コードのトランスフォームする際の Tips です`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`jscodeshift`}</code>{`を選択すると`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Ctrl + Space`}</code>{`で jscodeshift の補完が効くようになります`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`babel-plugin-macro`}</code>{`を選ぶとトランスフォーマのコード自体がそのまま babel-plugin として使えるようになるので、プラグイン作るときに捗るはずです`}</li>
    </ul>
    <h1>{`まとめ`}</h1>
    <p>{`後で参加メンバーに聞いてみましたが、伝えたかったことは、ちゃんと伝わっていた様子だったので安心しました。最後の Vue.js の v1 から v2 のマイグレーションのデモは紹介した結果、JavaScript AST 便利そうという感触になったと思います。`}</p>
    <p>{`現在弊社のプロダクトで、JavaScript AST をガッツリと使うようなプロジェクトはないのですが、Babel などは全プロダクトで使用しており、結構プラグインを多用しているところもあるので、いざというときの基礎知識として覚えておいて損はないはずです。`}</p>
    <p>{`こういった部分の勉強も欠かさず続けていきたいと改めて思う機会にもなりました。`}</p>
    <p>{`弊社の開発文化など気になる方は、こちらからどうぞ。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      